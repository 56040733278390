import axios from 'axios'
import { ali_oss_sts, ali_oss_url } from './urls'

var aliossApi = {
    uploadFile(file, token){
        return new Promise((resolve, reject)=>{
            axios.get(ali_oss_sts, {
                headers:{
                    'Authorization': 'Bearer ' + token
                }
            }).then(resp=>{
                console.log(resp)
                if(resp.status == 200){
                    var sts = resp.data.result
                    var oss = require('ali-oss')
                    var client = new oss({
                        accessKeyId: sts.access_key_id,
                        accessKeySecret: sts.access_key_secret,
                        stsToken: sts.sts_token,
                        region: sts.region,
                        bucket: sts.bucket
                    }) 
                    var fileHash = this.random_string(6) + '_' + new Date().getTime() + '.' + file.name.split('.').pop()
                    console.log(fileHash)
                    client.multipartUpload(fileHash, file).then(({result})=>{
                        console.log("upload success")
                        console.log(result)
                        resolve(ali_oss_url + fileHash)
                    }).catch(err=>{
                        console.log("upload fail 1")
                        console.log(err)
                        reject(err)
                    })
                } else {
                    console.log("upload fail 2")
                    reject(resp)
                }
            }).catch(err=>{
                console.log("upload fail 3")
                console.log(err)
                reject(err)
            })
        })
    },
    random_string(len) {
        len = len || 32;
        let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678', maxPos = chars.length, pwd = '';
        for (let i = 0; i < len; i++) {
            pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    },
}

export default aliossApi